
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        






























































.range-wrap {
  position: relative;
  padding-bottom: 32px;

  input[type='range'] {
    width: 100%;
    height: 8px;
    background-color: $grey-extra-light;
    border-radius: 4px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    appearance: none;
  }

  .range-button {
    position: absolute;
    top: 0;
    left: 50%;
    width: 25px;
    height: 25px;
    pointer-events: none;
    background-color: $primary;
    border-radius: 50%;
    transform: translate(-50%);
  }

  .range-value {
    position: absolute;
    top: 30px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    transform: translateX(-50%);
  }

  .range-progress {
    position: absolute;
    top: 9px;
    bottom: 4px;
    left: 0;
    width: 50%;
    height: 8px;
    pointer-events: none;
    background-color: $primary;
    border-radius: 4px;
  }
}
