
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.asset {
  position: relative;
  padding: 40px 34px;
  border: 1px solid #7c7f81;

  .add-icon {
    width: 17px;
    height: 17px;
    margin-right: 8px;
  }

  .chevron {
    width: 12px;
    height: 16px;
    transition: 0.5s ease all;
    transform: rotate(90deg);
    path {
      fill: #000;
    }
  }

  .arrow-active {
    transform: rotate(270deg);
  }

  .reorder {
    position: absolute;
    top: -12px;
    left: 20px;
    z-index: 1000;
    display: flex;
    align-items: center;
    padding: 0 16px;
    background-color: #fff;
    .dots-icon {
      width: 4px;
      height: auto;
      margin-right: 6px;
    }
  }

  .asset-actions {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    margin-top: 24px;
    .toggle-switch {
      flex: 1;
      gap: 10px;
    }
    .asset-actions-child {
      display: flex;
      gap: 10px;
    }
    .a-action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      span {
        margin-left: 6px;
      }
    }
    .hide-icon {
      width: 18px;
      height: 16px;
    }

    .delete-icon {
      width: 12px;
      height: 16px;
    }
  }

  .add-question {
    align-items: center;
    margin-top: 24px;
  }
}

.quiz,
.survey {
  .upload-csv-wrap {
    margin-top: 32px;
    .upload-csv {
      display: flex;
      flex-direction: row;
      column-gap: 16px;

      input[type='file'] {
        display: none;
      }

      .porsche-button {
        align-self: flex-start;
      }
    }

    .disclaimer {
      display: block;
      margin-top: 16px;
      font-size: 12px;
      color: #4a4a4a;

      &-list {
        margin-top: 4px;
        margin-left: 16px;
        text-indent: -16px;
        list-style: none;

        li {
          margin-bottom: 2px;

          &::before {
            padding-right: 8px;
            content: '-';
          }
        }
      }
    }
  }
}

.quiz {
  .quiz-header {
    display: flex;
    column-gap: 16px;

    .quiz-title {
      flex-basis: 80%;
    }

    .quiz-pass-percentage {
      flex-basis: 20%;
    }
  }
}

.content-lib-search {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  .search-heading {
    display: flex;
    column-gap: 8px;
    margin-bottom: 6px;
    text-transform: capitalize;
  }

  .search-query {
    margin-bottom: 24px;
    input {
      width: 100%;
      padding-left: 40px;
    }

    .icon-wrap {
      position: absolute;
      left: 13px;
      display: flex;
      align-items: center;
      height: 100%;

      .search-icon {
        width: 18px;
        height: auto;
      }
    }
  }

  .search-results {
    .search-asset {
      display: flex;
      flex-direction: column;
      .asset-info {
        display: flex;
        .asset-content {
          display: flex;
          flex: 1;
          column-gap: 16px;
          img,
          video {
            width: 75px;
            height: auto;
          }

          .asset-details {
            h3 {
              margin-bottom: 4px;
              font-size: 16px;
            }

            .view-media {
              display: flex;
              align-items: center;
              margin-bottom: 8px;
              font-size: 14px;
              cursor: pointer;

              .arrow-icon {
                width: auto;
                height: 16px;
              }
            }

            .tags {
              display: flex;
              column-gap: 4px;
            }
          }
        }
        .asset-select {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          .arrow-icon {
            width: auto;
            height: 20px;
          }
        }
      }
    }

    .arrow-icon {
      path:nth-child(2) {
        fill: $primary;
      }
    }
  }
}
