
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

































.breadcrumbs {
  margin-top: 40px;

  p {
    font-size: 14px;

    .link {
      font-size: 14px;
      color: #000;
    }

    span {
      text-transform: capitalize;
    }

    .divider {
      padding: 0 6px;
      color: #4a4a4a;
    }
  }

  .router-link-active {
    color: #000;
  }
}
