@font-face {
  font-family: 'primeicons';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('../../node_modules/primeicons/fonts/primeicons.eot');
  src: url('../../node_modules/primeicons/fonts/primeicons.eot?#iefix') format('embedded-opentype'),
    url('../../node_modules/primeicons/fonts/primeicons.ttf') format('truetype'),
    url('../../node_modules/primeicons/fonts/primeicons.woff') format('woff'),
    url('../../node_modules/primeicons/fonts/primeicons.svg?external#primeicons') format('svg');
}

.pi {
  display: inline-block;
  font-family: 'primeicons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pi::before {
  --webkit-backface-visibility: hidden;

  backface-visibility: hidden;
}

.pi-fw {
  width: 1.28571429em;
  text-align: center;
}

.pi-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.pi-send::before {
  content: '\e9ca';
}

.pi-slack::before {
  content: '\e9c9';
}

.pi-sun::before {
  content: '\e9c8';
}

.pi-moon::before {
  content: '\e9c7';
}

.pi-vimeo::before {
  content: '\e9c6';
}

.pi-youtube::before {
  content: '\e9c5';
}

.pi-flag::before {
  content: '\e9c4';
}

.pi-wallet::before {
  content: '\e9c3';
}

.pi-map::before {
  content: '\e9c2';
}

.pi-link::before {
  content: '\e9c1';
}

.pi-credit-card::before {
  content: '\e9bf';
}

.pi-discord::before {
  content: '\e9c0';
}

.pi-percentage::before {
  content: '\e9be';
}

.pi-euro::before {
  content: '\e9bd';
}

.pi-book::before {
  content: '\e9ba';
}

.pi-shield::before {
  content: '\e9b9';
}

.pi-paypal::before {
  content: '\e9bb';
}

.pi-amazon::before {
  content: '\e9bc';
}

.pi-phone::before {
  content: '\e9b8';
}

.pi-filter-slash::before {
  content: '\e9b7';
}

.pi-facebook::before {
  content: '\e9b4';
}

.pi-github::before {
  content: '\e9b5';
}

.pi-twitter::before {
  content: '\e9b6';
}

.pi-step-backward-alt::before {
  content: '\e9ac';
}

.pi-step-forward-alt::before {
  content: '\e9ad';
}

.pi-forward::before {
  content: '\e9ae';
}

.pi-backward::before {
  content: '\e9af';
}

.pi-fast-backward::before {
  content: '\e9b0';
}

.pi-fast-forward::before {
  content: '\e9b1';
}

.pi-pause::before {
  content: '\e9b2';
}

.pi-play::before {
  content: '\e9b3';
}

.pi-compass::before {
  content: '\e9ab';
}

.pi-id-card::before {
  content: '\e9aa';
}

.pi-ticket::before {
  content: '\e9a9';
}

.pi-file-o::before {
  content: '\e9a8';
}

.pi-reply::before {
  content: '\e9a7';
}

.pi-directions-alt::before {
  content: '\e9a5';
}

.pi-directions::before {
  content: '\e9a6';
}

.pi-thumbs-up::before {
  content: '\e9a3';
}

.pi-thumbs-down::before {
  content: '\e9a4';
}

.pi-sort-numeric-down-alt::before {
  content: '\e996';
}

.pi-sort-numeric-up-alt::before {
  content: '\e997';
}

.pi-sort-alpha-down-alt::before {
  content: '\e998';
}

.pi-sort-alpha-up-alt::before {
  content: '\e999';
}

.pi-sort-numeric-down::before {
  content: '\e99a';
}

.pi-sort-numeric-up::before {
  content: '\e99b';
}

.pi-sort-alpha-down::before {
  content: '\e99c';
}

.pi-sort-alpha-up::before {
  content: '\e99d';
}

.pi-sort-alt::before {
  content: '\e99e';
}

.pi-sort-amount-up::before {
  content: '\e99f';
}

.pi-sort-amount-down::before {
  content: '\e9a0';
}

.pi-sort-amount-down-alt::before {
  content: '\e9a1';
}

.pi-sort-amount-up-alt::before {
  content: '\e9a2';
}

.pi-palette::before {
  content: '\e995';
}

.pi-undo::before {
  content: '\e994';
}

.pi-desktop::before {
  content: '\e993';
}

.pi-sliders-v::before {
  content: '\e991';
}

.pi-sliders-h::before {
  content: '\e992';
}

.pi-search-plus::before {
  content: '\e98f';
}

.pi-search-minus::before {
  content: '\e990';
}

.pi-file-excel::before {
  content: '\e98e';
}

.pi-file-pdf::before {
  content: '\e98d';
}

.pi-check-square::before {
  content: '\e98c';
}

.pi-chart-line::before {
  content: '\e98b';
}

.pi-user-edit::before {
  content: '\e98a';
}

.pi-exclamation-circle::before {
  content: '\e989';
}

.pi-android::before {
  content: '\e985';
}

.pi-google::before {
  content: '\e986';
}

.pi-apple::before {
  content: '\e987';
}

.pi-microsoft::before {
  content: '\e988';
}

.pi-heart::before {
  content: '\e984';
}

.pi-mobile::before {
  content: '\e982';
}

.pi-tablet::before {
  content: '\e983';
}

.pi-key::before {
  content: '\e981';
}

.pi-shopping-cart::before {
  content: '\e980';
}

.pi-comments::before {
  content: '\e97e';
}

.pi-comment::before {
  content: '\e97f';
}

.pi-briefcase::before {
  content: '\e97d';
}

.pi-bell::before {
  content: '\e97c';
}

.pi-paperclip::before {
  content: '\e97b';
}

.pi-share-alt::before {
  content: '\e97a';
}

.pi-envelope::before {
  content: '\e979';
}

.pi-volume-down::before {
  content: '\e976';
}

.pi-volume-up::before {
  content: '\e977';
}

.pi-volume-off::before {
  content: '\e978';
}

.pi-eject::before {
  content: '\e975';
}

.pi-money-bill::before {
  content: '\e974';
}

.pi-images::before {
  content: '\e973';
}

.pi-image::before {
  content: '\e972';
}

.pi-sign-in::before {
  content: '\e970';
}

.pi-sign-out::before {
  content: '\e971';
}

.pi-wifi::before {
  content: '\e96f';
}

.pi-sitemap::before {
  content: '\e96e';
}

.pi-chart-bar::before {
  content: '\e96d';
}

.pi-camera::before {
  content: '\e96c';
}

.pi-dollar::before {
  content: '\e96b';
}

.pi-lock-open::before {
  content: '\e96a';
}

.pi-table::before {
  content: '\e969';
}

.pi-map-marker::before {
  content: '\e968';
}

.pi-list::before {
  content: '\e967';
}

.pi-eye-slash::before {
  content: '\e965';
}

.pi-eye::before {
  content: '\e966';
}

.pi-folder-open::before {
  content: '\e964';
}

.pi-folder::before {
  content: '\e963';
}

.pi-video::before {
  content: '\e962';
}

.pi-inbox::before {
  content: '\e961';
}

.pi-lock::before {
  content: '\e95f';
}

.pi-unlock::before {
  content: '\e960';
}

.pi-tags::before {
  content: '\e95d';
}

.pi-tag::before {
  content: '\e95e';
}

.pi-power-off::before {
  content: '\e95c';
}

.pi-save::before {
  content: '\e95b';
}

.pi-question-circle::before {
  content: '\e959';
}

.pi-question::before {
  content: '\e95a';
}

.pi-copy::before {
  content: '\e957';
}

.pi-file::before {
  content: '\e958';
}

.pi-clone::before {
  content: '\e955';
}

.pi-calendar-times::before {
  content: '\e952';
}

.pi-calendar-minus::before {
  content: '\e953';
}

.pi-calendar-plus::before {
  content: '\e954';
}

.pi-ellipsis-v::before {
  content: '\e950';
}

.pi-ellipsis-h::before {
  content: '\e951';
}

.pi-bookmark::before {
  content: '\e94e';
}

.pi-globe::before {
  content: '\e94f';
}

.pi-replay::before {
  content: '\e94d';
}

.pi-filter::before {
  content: '\e94c';
}

.pi-print::before {
  content: '\e94b';
}

.pi-align-right::before {
  content: '\e946';
}

.pi-align-left::before {
  content: '\e947';
}

.pi-align-center::before {
  content: '\e948';
}

.pi-align-justify::before {
  content: '\e949';
}

.pi-cog::before {
  content: '\e94a';
}

.pi-cloud-download::before {
  content: '\e943';
}

.pi-cloud-upload::before {
  content: '\e944';
}

.pi-cloud::before {
  content: '\e945';
}

.pi-pencil::before {
  content: '\e942';
}

.pi-users::before {
  content: '\e941';
}

.pi-clock::before {
  content: '\e940';
}

.pi-user-minus::before {
  content: '\e93e';
}

.pi-user-plus::before {
  content: '\e93f';
}

.pi-trash::before {
  content: '\e93d';
}

.pi-external-link::before {
  content: '\e93c';
}

.pi-window-maximize::before {
  content: '\e93b';
}

.pi-window-minimize::before {
  content: '\e93a';
}

.pi-refresh::before {
  content: '\e938';
}

.pi-user::before {
  content: '\e939';
}

.pi-exclamation-triangle::before {
  content: '\e922';
}

.pi-calendar::before {
  content: '\e927';
}

.pi-chevron-circle-left::before {
  content: '\e928';
}

.pi-chevron-circle-down::before {
  content: '\e929';
}

.pi-chevron-circle-right::before {
  content: '\e92a';
}

.pi-chevron-circle-up::before {
  content: '\e92b';
}

.pi-angle-double-down::before {
  content: '\e92c';
}

.pi-angle-double-left::before {
  content: '\e92d';
}

.pi-angle-double-right::before {
  content: '\e92e';
}

.pi-angle-double-up::before {
  content: '\e92f';
}

.pi-angle-down::before {
  content: '\e930';
}

.pi-angle-left::before {
  content: '\e931';
}

.pi-angle-right::before {
  content: '\e932';
}

.pi-angle-up::before {
  content: '\e933';
}

.pi-upload::before {
  content: '\e934';
}

.pi-download::before {
  content: '\e956';
}

.pi-ban::before {
  content: '\e935';
}

.pi-star-o::before {
  content: '\e936';
}

.pi-star::before {
  content: '\e937';
}

.pi-chevron-left::before {
  content: '\e900';
}

.pi-chevron-right::before {
  content: '\e901';
}

.pi-chevron-down::before {
  content: '\e902';
}

.pi-chevron-up::before {
  content: '\e903';
}

.pi-caret-left::before {
  content: '\e904';
}

.pi-caret-right::before {
  content: '\e905';
}

.pi-caret-down::before {
  content: '\e906';
}

.pi-caret-up::before {
  content: '\e907';
}

.pi-search::before {
  content: '\e908';
}

.pi-check::before {
  content: '\e909';
}

.pi-check-circle::before {
  content: '\e90a';
}

.pi-times::before {
  content: '\e90b';
}

.pi-times-circle::before {
  content: '\e90c';
}

.pi-plus::before {
  content: '\e90d';
}

.pi-plus-circle::before {
  content: '\e90e';
}

.pi-minus::before {
  content: '\e90f';
}

.pi-minus-circle::before {
  content: '\e910';
}

.pi-circle-on::before {
  content: '\e911';
}

.pi-circle-off::before {
  content: '\e912';
}

.pi-sort-down::before {
  content: '\e913';
}

.pi-sort-up::before {
  content: '\e914';
}

.pi-sort::before {
  content: '\e915';
}

.pi-step-backward::before {
  content: '\e916';
}

.pi-step-forward::before {
  content: '\e917';
}

.pi-th-large::before {
  content: '\e918';
}

.pi-arrow-down::before {
  content: '\e919';
}

.pi-arrow-left::before {
  content: '\e91a';
}

.pi-arrow-right::before {
  content: '\e91b';
}

.pi-arrow-up::before {
  content: '\e91c';
}

.pi-bars::before {
  content: '\e91d';
}

.pi-arrow-circle-down::before {
  content: '\e91e';
}

.pi-arrow-circle-left::before {
  content: '\e91f';
}

.pi-arrow-circle-right::before {
  content: '\e920';
}

.pi-arrow-circle-up::before {
  content: '\e921';
}

.pi-info::before {
  content: '\e923';
}

.pi-info-circle::before {
  content: '\e924';
}

.pi-home::before {
  content: '\e925';
}

.pi-spinner::before {
  content: '\e926';
}
