
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        









































































































.hotspot-img {
  position: relative;
  max-width: 800px;

  .hotspot-pins {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    .pin {
      position: absolute;
    }
  }
}

.pin-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 8px #0003;
}

.hotspot-pin {
  display: flex;
  column-gap: 16px;
  padding: 0 2px;
  margin: 12px 0 16px;

  .pin-content {
    flex: 1;
    .porsche-button {
      padding: 6px 12px 6px 8px;
      margin-top: 6px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
