
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        


































































































































































































































































































































































































































































































































































































































.module-view {
  position: relative;
  width: 100%;

  .save-content {
    margin-top: 24px;
  }

  .module-content {
    .mutltiple-inputs {
      gap: 60px;

      .input {
        margin-bottom: 0;
      }

      .date-time {
        display: flex;
        flex-direction: column;
        .date-time-options {
          display: flex;
          gap: 16px;

          .date-picker {
            margin-bottom: 0;
          }
        }
      }
    }

    .asset-builder {
      .view-title {
        margin-bottom: 24px;
      }
      .asset-builders {
        .asset-name {
          text-transform: capitalize;
        }
      }
    }
  }
}
