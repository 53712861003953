
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        



























































































































































































































































































































































































































































.workbook-users {
  margin-top: 40px;

  h1 {
    font-size: 36px;
  }

  .summary {
    display: flex;
    margin: 8px 0 28px;
  }

  .user-content {
    position: relative;
    min-height: 70vh;

    .search {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 62px;
      margin: 25px 0;

      .svg-icon {
        width: 24px;
      }
      input {
        flex-grow: 1;
        height: 40px;
        padding-right: 24px;
        padding-left: 4px;
        margin-left: 24px;
        font-size: 16px;
        border: 0;
        border-bottom: 1px solid #c9cacb;
      }
      .cancel-btn {
        position: absolute;
        right: 0;
        width: 20px;
        height: 100%;
      }
    }

    .user-filter {
      position: relative;
      display: flex;
      margin: 25px 0;

      .filter-options {
        z-index: 99;
        display: flex;
        flex: 1;
        gap: 10px;

        .filter {
          position: relative;
          z-index: 99;
          align-items: center;
          padding: 10px 12px;
          cursor: pointer;
          background-color: #fff;
          border: 1px solid #626669;
          .filter-icon {
            height: 16px;
            margin-left: 18px;
          }
        }

        .filter-menu-active {
          border-bottom-color: transparent;
        }
      }

      .filter-menu-wrap {
        position: absolute;
        top: 48px;
        left: 349px;
        z-index: 98;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        width: 100%;
        max-width: 550px;
        padding: 24px 26px;
        background-color: #fff;
        border: 1px solid #626669;

        .filter-menu {
          display: flex;
          gap: 16px;

          .column {
            flex: 1;

            .filter-item {
              margin-bottom: 16px;
            }

            .sessions-filter {
              label {
                display: block;
                margin-bottom: 8px;
              }

              .checkbox {
                margin-bottom: 8px;

                .check-icon {
                  opacity: 0;
                }

                input[type='checkbox']:checked ~ .check-icon {
                  opacity: 1;
                }

                span {
                  font-size: 14px;
                }
              }
            }
          }
        }

        .filter-actions {
          display: flex;
          gap: 16px;
        }
      }
    }

    .users {
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 70vh;
      margin-top: 40px;

      .labels,
      .user {
        display: flex;
        gap: 16px;
        span {
          flex: 1;
          &:last-child {
            flex: 3;
          }
        }
      }

      hr {
        margin: 16px 0;
      }

      .labels-wrap {
        .labels {
          span {
            font-weight: 500;
          }
        }
      }

      .user-wrap {
        position: relative;

        .user {
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .no-users {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 270px;
          color: $grey-light;
          border: 1px solid #707070;

          .user-icon {
            width: auto;
            height: 65px;
            margin-bottom: 24px;
            path {
              fill: $grey-light;
            }
          }

          p {
            text-align: center;
          }
        }
      }
    }
  }
}
