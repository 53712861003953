
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        




























































































































// Nav defaults
@import '../assets/scss/navigation.scss';

.navigation {
  position: relative;
  padding: 15px 0 20px 0;

  nav {
    position: relative;
    display: flex;
    flex-direction: column;

    .svg-icon {
      width: 17px;
    }

    .nav-top {
      .search-navigation {
        min-height: 62px;

        input {
          height: 40px;
        }
      }
    }

    .line {
      bottom: 43px;
    }

    .search-active {
      position: relative;
      bottom: initial;
    }

    .nav-bottom {
      .navigation-links {
        z-index: 101;
        margin-top: 16px;

        .nav-link {
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.pointer {
  cursor: pointer;
}
