
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        



























































































































// Nav defaults
@import '../assets/scss/navigation.scss';

.mobile-navigation {
  position: relative;
  padding: 12px 0 0 0;

  nav {
    .nav-top {
      display: flex;

      .search-navigation {
        min-height: 62px;
        .close {
          margin-right: 0;
        }
      }

      .content {
        h2 {
          margin-right: 8px;
          font-size: 16px;
        }

        img {
          width: 77px;
          outline: 20px solid #fff;
        }

        .user-settings {
          gap: 6px;
          .link {
            margin-right: 0;
          }
        }
      }
    }

    .line {
      bottom: 60px;
    }

    .search-active {
      position: relative;
      bottom: initial;
    }

    .nav-bottom {
      margin-top: 18px;

      .navigation-links {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }
}
