
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        
































































































































































































































































































































































































































































































































































































.wb-module-error {
  .porsche-button {
    margin-top: 24px;
  }
}

.wavier {
  flex-direction: column;

  .wavier-asset {
    display: flex;
    column-gap: 16px;
    margin-bottom: 24px;

    .wb-asset-info {
      margin-top: 0 !important;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
  }
}

.wb-test {
  .notification-alert {
    margin-bottom: 16px;
  }

  h2,
  h3 {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .q-wrap {
    padding: 20px 40px 26px;
    margin-bottom: 18px;
    background-color: $white-smoke;
    border: 2px solid transparent;

    &-invalid {
      border-color: $primary;
    }

    &:nth-last-of-type(1) {
      margin-bottom: 30px;
    }

    .q-heading {
      margin-bottom: 16px;
      .q-question {
        position: relative;
        margin-bottom: 16px;

        .q-number {
          position: absolute;
          top: 0;
          left: -25px;
        }
      }
    }

    .q-answers {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      .checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        input[type='checkbox'] {
          background: #fff;
        }
        input[type='checkbox']:checked {
          background: #000;
        }
      }

      .circle-checkbox {
        input[type='radio'] {
          background: #fff;
          border: 1px solid $grey;
        }
      }

      .vue-multiselect {
        max-width: 300px;
      }

      .multiline-res,
      .singleline-res {
        padding: 12px;
        font-size: 16px;
        border: 1px solid $grey-light;
        &:focus {
          outline: none;
        }
      }

      .multiline-res {
        height: 150px;
        resize: none;
      }

      .range-wrap {
        position: relative;
        padding-bottom: 32px;

        input[type='range'] {
          width: 100%;
          height: 8px;
          background-color: $grey-extra-light;
          border-radius: 4px;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          appearance: none;
        }

        .range-button {
          position: absolute;
          top: 0;
          left: 50%;
          width: 25px;
          height: 25px;
          pointer-events: none;
          background-color: $primary;
          border-radius: 50%;
          transform: translate(-50%);
        }

        .range-value {
          position: absolute;
          top: 30px;
          left: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          transform: translateX(-50%);
        }

        .range-progress {
          position: absolute;
          top: 9px;
          bottom: 4px;
          left: 0;
          width: 50%;
          height: 8px;
          pointer-events: none;
          background-color: $primary;
          border-radius: 4px;
        }
      }
    }
  }
}
