
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

































.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;

  h1 {
    margin-bottom: 16px;
  }
}
