
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        
































































































































































.workbook-wrap {
  .workbook {
    gap: 16px;

    .image {
      position: relative;
      width: 150px;
      height: 150px;
      cursor: pointer;

      .default-img {
        border: 1px solid $grey;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      // override component styling
      .favorite-toggle {
        top: 5px;
        right: 5px;
      }
    }

    .content {
      flex: 1;
      h3 {
        margin-bottom: 4px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }

      .tags {
        gap: 6px;

        .tag {
          padding: 4px 8px;
          font-size: 12px;
          font-weight: 400;
          background-color: #e3e4e5;
          border-radius: 2px;
        }
      }

      p {
        margin: 16px 0 12px 0;
      }

      .options {
        align-items: center;
        margin-top: auto;

        .edit-options {
          display: flex;
          flex: 1;
          gap: 12px;
          justify-content: flex-end;

          span,
          .option {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .tool-tip {
              top: 30px;
              right: -13px;
              padding: 8px 12px;
            }

            &:hover {
              .tool-tip {
                opacity: 1;
              }
            }
          }

          .inactive {
            .icon {
              path {
                fill: $grey-light;
              }
            }
          }

          .icon {
            width: auto;
            height: 18px;
          }
        }
      }
    }
  }

  .mobile-workbook {
    .content {
      p {
        font-size: 12px;
      }

      .options {
        flex: 1;
        align-items: flex-start;
        .tags {
          margin-bottom: 16px;
        }
      }
    }
  }

  hr {
    margin: 30px 0;
    background-color: #c9cacb;
  }

  .mobile-hr {
    margin: 20px 0;
  }
}
