
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

























































































































































































.palms {
  margin-top: 40px;

  .heading {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    h1 {
      font-size: 36px;
      font-weight: 500;
    }

    .upload-status-enter-active,
    .upload-status-leave-active {
      transition: 0.3s ease all;
    }

    .upload-status-enter,
    .upload-status-leave-to {
      opacity: 0;
    }

    .status-msg {
      display: flex;
      align-items: center;
      margin-left: 24px;
      font-size: 16px;
    }

    .success {
      color: $green;
    }

    .error {
      color: $primary;
    }

    .cancel-icon {
      width: auto;
      height: 24px;
      margin-right: 6px;
      path {
        fill: $primary;
      }
    }

    .check-icon {
      width: auto;
      height: 16px;
      margin-right: 10px;
    }
  }

  .palms-tool {
    display: flex;
    gap: 32px;

    .icon {
      width: auto;
      height: 20px;
      margin-right: 6px;
    }

    .upload,
    .history {
      flex: 1;
    }

    .active-dropbox {
      background-color: rgba(201, 202, 203, 0.3);
    }

    .upload {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;
      justify-content: center;
      min-height: 415px;
      max-height: 415px;
      border: 1px dashed #96989a;

      input {
        display: none;
      }
    }

    .history {
      display: flex;
      flex-direction: column;

      .history-data {
        min-height: 415px;
      }

      .history-labels {
        display: flex;
        gap: 16px;
        width: 100%;
        span {
          display: block;
          flex: 1;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .history-items {
        .history-item-wrap {
          display: flex;
          gap: 16px;
          padding: 14px;

          .item {
            flex: 1;
            .icon {
              height: 14px;
            }
          }

          .title {
            span {
              display: inline-block;
              width: 200px;
              height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
