
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        




















































.fav-icon {
  width: 20px;
  height: auto;
}

.favorite-toggle-absolute {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: $charcoal;
  border-radius: 50%;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  .fav-icon {
    g {
      path {
        fill: #fff;
      }
    }
  }
}

.favorite-toggle {
  display: flex;
  column-gap: 8px;

  .fav-icon {
    g {
      path {
        fill: $primary;
      }
    }
  }
}

.favorite-item-active {
  background-color: $primary;
}
