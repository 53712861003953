
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        






















































































































































































































































































































































































































































































































































































































































































































































































































































































































.create-workbook {
  display: flex;
  flex-direction: column;

  .workbook-setup {
    position: relative;
    .workbook {
      display: flex;
      gap: 32px;
      min-height: 100vh;
      margin: 24px 0 75px 0;

      ul {
        list-style: none;
      }

      .workbook-nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        font-size: 16px;
        background-color: #f2f2f2;
        border: 1px solid #c9cacb;

        li {
          display: flex;
          padding: 14px 8px 14px 28px;
          cursor: pointer;
          background-color: #fff;
          border-top: 1px solid #c9cacb;

          &:last-child {
            border-bottom: 1px solid #c9cacb;
          }
        }

        .nav-item {
          &:hover {
            .cancel-icon {
              opacity: 1;
            }
          }
        }

        .cancel-icon {
          align-self: flex-end;
          margin-right: 0;
          margin-left: auto;
          opacity: 0;
          transition: 150ms ease-in-out;
          path {
            pointer-events: none;
            fill: #000;
          }
        }

        .module {
          flex-direction: column;
          padding: 0;
          background-color: #f2f2f2;

          .module-details {
            display: flex;
            align-items: center;
            padding: 14px 7px 14px 28px;
            background-color: #fff;
            border-bottom: 1px solid #c9cacb;

            .module-icons {
              display: flex;
              align-items: center;
              margin-left: auto;
            }
            .key-icon {
              width: 24px;
              height: 12px;
              margin-right: 16px;
              margin-left: auto;
              path {
                fill: #000;
              }
            }
          }

          .active {
            .cancel-icon {
              opacity: 1;
              path {
                fill: white;
              }
            }

            .key-icon {
              path {
                fill: white;
              }
            }
          }
        }

        .active {
          color: #fff;
          background-color: #323639 !important;
          border: 1px solid #c9cacb;
        }

        .title {
          font-weight: 500;
          border: none;
        }

        .add {
          background-color: #f9f9f9;
        }

        .add-icon {
          width: auto;
          height: 17px;
          margin-right: 7px;
        }

        .workbook-section {
          margin-left: 45px;

          li {
            align-items: center;
            border-left: 1px solid #c9cacb;

            &:first-child {
              border-top: 0;
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }

      .workbook-content-wrap {
        position: relative;
        width: 100%;
        min-height: 100vh;

        .view-summary {
          margin-bottom: 24px;
        }

        .create-workbook-cd {
          display: flex;
          gap: 32px;
          min-height: 100vh;

          .left {
            display: flex;
            flex-direction: column;
            max-width: 250px;

            img {
              width: 100%;
              height: 260px;
              object-fit: cover;
            }

            .default-img {
              border: 1px solid $grey;
            }

            .porsche-button {
              align-self: center;
              margin-top: 16px;
            }

            input[type='file'] {
              display: none;
            }

            .img-req {
              display: flex;
              justify-content: center;
              margin-top: 6px;
              font-size: 12px;
              color: #4a4a4a;
            }
          }

          .right {
            flex: 1;
            max-width: 550px;

            .sessions {
              display: flex;
              flex-direction: column;
              row-gap: 30px;

              .session {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                &-field {
                  color: $grey-light;
                  .label {
                    color: #000;
                  }
                }

                &-date {
                  display: flex;
                  column-gap: 20px;
                }
              }
            }

            .mutltiple-inputs {
              h4 {
                margin-top: 16px;
                margin-bottom: 10px;
                font-size: 16px;
              }

              .additional-info {
                display: block;
                margin-top: 12px;
                font-size: 12px;
                line-height: 18px;
              }

              .checkbox {
                span {
                  display: inline;
                  margin: 0 0 0 16px !important;
                  font-size: 16px !important;
                }
                input[type='checkbox'] {
                  position: relative;
                  width: 53px;
                  height: 34px;
                  background: #d5001c;
                  border: none;
                  border-radius: 16px;
                  outline: none;
                  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                  transition: 0.5s ease all;
                  -webkit-appearance: none;
                }

                input[type='checkbox']::before {
                  position: absolute;
                  top: 3px;
                  left: 3px;
                  width: 28px;
                  height: 28px;
                  content: '';
                  background: #fff;
                  border-radius: 20px;
                  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                  transition: 0.5s ease all;
                  transform: scale(1.1);
                }

                input:checked[type='checkbox']::before {
                  left: 22px;
                  background: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}
