
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        





















































































































































































































































































































































































































































































































































































































.survey-results-wrap {
  position: relative;

  // Side Panel
  .slide-panel-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.6);

    .slide-panel {
      position: fixed;
      right: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 550px;
      height: 100%;
      min-height: 100vh;
      padding: 36px 36px 76px 36px;
      overflow: scroll;
      background-color: #fff;

      .close-report {
        display: flex;
        align-items: center;
        margin-bottom: 36px;
        cursor: pointer;

        .arrow-red {
          width: 6px;
          height: 13px;
          margin-right: 6px;
          transform: rotate(180deg);
        }
      }

      h2 {
        margin-bottom: 16px;
        font-size: 24px;
        font-weight: 500;
      }

      .question-label {
        margin-bottom: 4px;
      }

      .responses {
        max-height: 500px;
        padding: 18px;
        margin-bottom: 20px;
        overflow: scroll;
        border: 1px solid $grey;

        .response {
          hr {
            margin: 6px 0;
          }
        }
      }

      .porsche-button {
        align-self: flex-start;
      }
    }
  }

  .survey-results {
    .survey-content-wrap {
      position: relative;
    }

    .survey-questions {
      margin-top: 16px;
      .question {
        padding: 24px;
        margin-bottom: 16px;
        border: 1px solid #c9cacb;

        .question-label {
          span {
            color: #d5001c;
          }
        }

        .question-results {
          padding-left: 20px;
          margin-top: 16px;

          .result {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-bottom: 8px;

            .result-label {
              min-width: 30px;
            }

            .result-bar {
              height: 12px;
              background-color: #d5001c;
            }
          }
        }

        .text-response {
          p:nth-child(1) {
            font-size: 24px;
          }
          p:nth-child(2) {
            margin-bottom: 16px;
            font-weight: 500;
          }
        }

        .multiple-rows {
          .result {
            flex-direction: column;
            align-items: flex-start;
            .result-response {
              display: flex;
              gap: 8px;
              align-items: center;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
