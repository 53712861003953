.container {
  width: 100%;
  max-width: 1256px;
  padding: 0 10px;
  margin: 0 auto;
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column;
}

.link {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.button-link {
  display: flex;
  align-items: center;
  padding: 12px 24px 12px 16px;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  background-color: $primary;
  border: 1px solid transparent;
}

.button-link-light {
  color: #000;
  background-color: transparent;
  border-color: $charcoal;
}

.nav-link {
  position: relative;
  z-index: 101;
  padding-bottom: 16px;
  color: inherit;
  text-decoration: none;
  transition: 0.3s ease border;

  &:hover {
    color: $primary;
    &::after {
      background-color: $primary;
    }

    .svg-icon {
      path {
        fill: $primary;
      }
    }
  }

  &::after {
    position: absolute;
    bottom: 8px;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
  }
}

.active-item {
  color: $primary;
  &::after {
    background-color: $primary;
  }
}

.router-link-active {
  color: $primary;

  &::after {
    background-color: $primary;
  }

  .svg-icon {
    path {
      fill: $primary;
    }
  }
}
