
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        


























@keyframes circle-loading {
  to {
    transform: rotateZ(360deg);
  }
}

.circle-spinner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  span {
    display: block;
    width: 70px;
    height: 70px;
    border: 6px solid transparent;
    border-top-color: #000;
    border-radius: 50%;
    animation: circle-loading 1200ms ease infinite;
  }
}

.section-spinner {
  position: absolute;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 25px;
}

.small-spinner {
  position: absolute;
  width: 24px;
  height: 17px;
  padding-top: 0;
  span {
    width: 24px;
    height: 17px;
    border: 2px solid transparent;
    border-top-color: #000;
  }
}
