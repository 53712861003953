
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        



























.alert-enter-active {
  transition: 0.3s ease all;
}

.alert-enter {
  opacity: 0;
}

.status-msg {
  display: flex;
  align-items: center;
  margin-left: 0;
  font-size: 16px;

  .cancel-icon {
    width: auto;
    height: 24px;
    margin-right: 6px;
    path {
      fill: $primary;
    }
  }
}

.error {
  color: $primary;
}

.inline {
  margin-left: 24px;
}

.block {
  margin-bottom: 16px;
}
