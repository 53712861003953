
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        























































































































































































































































































































































































@import '@/assets/scss/survey-quiz.scss';
.question {
  .answer-type {
    flex-wrap: wrap;
    gap: 16px;
    align-items: flex-end;
    margin-bottom: 8px;

    .vue-multiselect {
      margin-bottom: 0;
    }

    .input {
      flex: initial;
      margin-bottom: 0;
    }

    .single {
      width: 200px;
    }

    .checkbox {
      padding-bottom: 14px !important;
      margin-bottom: 0;
    }

    .slider-options {
      display: flex;
      gap: 8px;
    }
  }

  .answers {
    input[type='radio'] {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border: 1px solid $grey;
      border-radius: 50%;
      -webkit-appearance: none;
    }

    input[type='radio']:checked::after {
      position: absolute;
      width: 16px;
      height: 16px;
      content: '';
      background-color: #000;
      border-radius: 50%;
    }

    .a-span-light {
      margin-left: 4px;
      color: $grey-light;
    }

    .a-span-sm {
      font-size: 12px;
      color: $grey-light;
    }

    .add-option {
      display: flex;
      align-items: center;
      margin-top: 8px;
      cursor: pointer;
    }

    .true-false {
      margin-top: 8px;

      span {
        margin-left: 8px;
        font-size: 16px;
      }
    }

    .multiple-options {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      margin-top: 8px;

      .option {
        display: flex;
        gap: 8px;
        align-items: center;
        .checkbox {
          margin-bottom: 0;
        }

        input[type='text'] {
          flex: 1;
          width: 100%;
          padding: 10px;
          font-size: 16px;
        }

        input[type='checkbox'] {
          margin-right: 0;
        }
      }
    }

    .dropdown {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      margin-top: 8px;

      .d-input {
        input {
          padding: 12px;
        }
      }
    }
  }
}
