// default styling for survey & quizzes
.survey-q,
.quiz-q {
  position: relative;
  padding: 40px 20px;
  margin-top: 34px;
  border: 1px solid #c9cacb;

  &-invalid {
    border-color: $primary;
  }

  .question-heading {
    display: flex;
    align-items: center;
    p {
      flex: 1;
      margin-right: 40px;
    }
  }

  .question-content {
    margin-top: 20px;
  }

  label {
    margin-bottom: 6px;
  }

  input:not([type='checkbox']),
  select,
  textarea {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #fff;
    border: 1px solid #626669;
    &:focus {
      outline: none;
    }
  }

  .input,
  .textarea {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .invalid-field::v-deep {
    border-color: $primary;

    .multiselect__tags {
      border-color: $primary;
    }
  }

  .add-icon {
    width: 17px;
    height: 17px;
    margin-right: 8px;
  }

  .chevron {
    width: 12px;
    height: 16px;
    transition: 0.5s ease all;
    transform: rotate(90deg);
    path {
      fill: #000;
    }
  }

  .arrow-active {
    transform: rotate(270deg);
  }

  .reorder {
    position: absolute;
    top: -12px;
    left: 20px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    background-color: #fff;
    .dots-icon {
      width: 4px;
      height: auto;
      margin-right: 6px;
    }
  }

  .asset-actions {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    margin-top: 24px;

    .a-action {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        margin-left: 6px;
      }
    }
    .hide-icon {
      width: 18px;
      height: 16px;
    }

    .delete-icon {
      width: 12px;
      height: 16px;
    }
  }

  .placeholder {
    color: $grey-light;
  }
}
