
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        
// default styling used throughout the view workbook route
.view-workbook {
  .workbook {
    max-width: 1920px;
    margin: 0 auto;

    // icons
    .icon {
      cursor: pointer;
    }

    .favorite-icon {
      width: 18px;
      height: auto;

      path {
        fill: transparent;
        stroke: $primary;
        stroke-width: 1;
        transition: 0.1s ease-in-out;
      }
    }

    .dl-icon {
      width: 20px;
      height: auto;
      path {
        fill: $primary;
      }
    }

    .external-icon {
      width: auto;
      height: 24px;
      path {
        fill: $primary;
      }
    }

    .link-icon {
      width: 18px;
      height: auto;
    }

    hr {
      margin: 30px 0;
      border: 1px solid $grey-extra-light;
    }

    .workbook-content {
      .label {
        margin-bottom: 12px;
        font-weight: 500;
      }

      .description {
        margin-bottom: 32px;
        .value {
          line-height: 1.6;
        }
      }

      .wb-view {
        position: relative;

        h1 {
          margin-bottom: 16px;
          font-size: 24px;
          font-weight: 500;
        }

        .wb-assets {
          // asset column styles
          .wb-asset-column {
            flex-direction: column;
          }

          .wb-asset {
            display: flex;
            gap: 16px;
            h2 {
              margin-bottom: 8px;
              font-size: 16px;
              font-weight: 500;
            }

            .tag {
              display: inline-block;
              margin-bottom: 14px;
              text-transform: capitalize;
            }

            &-heading {
              display: flex;
              column-gap: 16px;
            }

            &-info {
              .notes {
                display: flex;
                flex-direction: column;
                row-gap: 8px;

                .label {
                  font-weight: 500;
                }

                margin-bottom: 16px;
              }

              .actions {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                row-gap: 12px;
                column-gap: 18px;

                .action {
                  position: relative;
                  display: flex;
                  column-gap: 8px;
                  align-items: center;
                  color: inherit;
                  text-decoration: none;
                  cursor: pointer;
                }
              }
            }
          }

          .static-img {
            display: block;
            align-self: flex-start;
            max-width: 150px;
            height: auto;
          }

          .asset-image,
          .asset-video {
            display: block;
            width: 100%;
          }

          .pdf {
            margin-top: 16px;
          }
        }
      }
    }

    .wb-intro,
    .wb-module {
      padding-right: 60px;
    }

    .wb-view-mobile {
      hr {
        margin: 20px 0;
      }

      .wb-asset {
        &-info {
          margin-top: 16px;
        }
      }
    }
  }

  .mobile-workbook {
    .wb-intro,
    .wb-module {
      padding-right: 0;
    }
  }
}
