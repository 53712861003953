
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        


















































































































































































































































































































































.workbook-wrap {
  .workbook-preview {
    padding: 38px 0;
    background-color: #c9cacb;

    .preview-content {
      display: flex;
      column-gap: 32px;

      p {
        flex: 1;
        max-width: 630px;
      }

      .porsche-button {
        display: flex;
        align-self: center;
        margin-left: auto;
      }
    }
  }

  .workbook {
    display: flex;
    column-gap: 32px;
    min-height: 600px;
    margin: 22px auto 80px auto;

    ul {
      list-style: none;
    }

    .workbook-nav {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 300px;

      .menu-lines {
        width: 36px;
        min-height: 36px;
        margin-left: 10px;
      }

      .workbook-nav-items {
        height: 100%;
        border-top: 1px solid $grey-extra-light;
        border-right: 1px solid $grey-extra-light;
      }

      .nav-title {
        background-color: $white-smoke;
      }

      .nav-item {
        display: flex;
        align-items: center;
        height: 52px;
        padding: 0 0 0 28px;
        cursor: pointer;
        border-bottom: 1px solid $grey-extra-light;

        .lock-icon-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 35px;
          height: 35px;
          margin-right: 16px;
          margin-left: auto;
          background-color: $white-smoke;
          border-radius: 50%;

          .lock-icon {
            width: 24px;
            height: 24px;
            path {
              fill: #000;
            }
          }
        }
      }

      .nav-active {
        color: #fff;
        background-color: #323639 !important;
        border: 1px solid #c9cacb;

        .lock-icon {
          path {
            fill: white;
          }
        }
      }

      .nav-disabled {
        pointer-events: none;
      }

      .nav-modules {
        .nav-sections {
          .nav-section {
            padding-left: 48px;
          }
        }
      }
    }

    .workbook-content {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: column;

      .wb-intro {
        display: flex;
        column-gap: 32px;

        .image {
          .default-img {
            border: 1px solid $grey;
          }

          img {
            display: block;
            width: 250px;
            height: 250px;
            object-fit: cover;
          }
        }

        .content {
          flex: 1;

          h1 {
            font-size: 36px;
            font-weight: 500;
          }

          .event-details {
            display: flex;
            column-gap: 6px;
            margin: 8px 0 32px;
          }

          .favorites {
            display: flex;
            column-gap: 8px;
            margin-top: 32px;
          }

          .vehicle-info {
            display: flex;
            column-gap: 60px;
            margin-bottom: 32px;
          }

          .wb-req {
            display: flex;
            column-gap: 16px;
            margin-bottom: 32px;

            .req-column {
              display: flex;
              flex: 1;
              flex-direction: column;

              .values {
                display: flex;
                flex-wrap: wrap;
                column-gap: 8px;

                span {
                  position: relative;
                  &::after {
                    position: absolute;
                    right: -4px;
                    content: ',';
                  }

                  &:last-child {
                    &::after {
                      content: '';
                    }
                  }
                }
              }

              .no-result {
                color: $grey-light;
              }
            }

            .c-designer {
              flex: initial;
              flex-basis: 45%;
            }
          }
        }
      }
    }
  }

  .mobile-workbook {
    position: relative;
    flex-direction: column;
    margin-top: 30px;

    .workbook-nav {
      max-width: 100%;
      height: 100%;

      .workbook-nav-items {
        position: absolute;
        top: 30px;
        z-index: 101;
        width: 100%;
        height: 100%;
        margin-top: 10px;
        overflow: scroll;
        background-color: #fff;
        border-right: none;
      }
    }

    .workbook-content {
      margin-top: 20px;

      .wb-intro {
        .content {
          .wb-req {
            flex-direction: column;
            row-gap: 16px;
          }
        }
      }
    }
  }
}
