
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        






















































































































































































































.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 0 10px;
  overflow-y: scroll;
  background-color: rgb(0, 0, 0, 0.5);

  .modal {
    width: 100%;
    max-width: 540px;
    padding: 32px 42px;
    margin-top: 180px;
    background-color: #fff;

    h1 {
      font-size: 24px;
      font-weight: 500;
    }

    h2 {
      margin-top: 8px;
      font-size: 15px;
      font-weight: 400;
    }

    .input {
      margin-top: 30px;

      p {
        margin-bottom: 6px;
        font-size: 18px;
      }

      input {
        width: 100%;
        padding: 12px;
        font-size: 16px;
        border: 1px solid #626669;
      }
    }

    .disclaimer {
      display: block;
      margin-top: 45px;
    }

    .actions {
      display: flex;
      gap: 8px;
      margin-top: 16px;

      .inactive {
        pointer-events: none;
        background-color: $grey-extra-light;
      }
    }
  }

  .video,
  .image {
    max-width: 800px;
    video,
    img {
      width: 100%;
      height: auto;
      margin-top: 16px;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    }
  }

  .confirm,
  .popup {
    h1,
    p {
      margin-bottom: 16px;
    }
  }
}

.pop-up {
  .modal {
    max-width: 500px;
  }
}

.access-code {
  .modal {
    max-width: 568px;
    padding: 32px 24px;
    .input {
      p {
        margin-bottom: 10px;
        font-size: 16px;
      }
    }
  }
}
