// common styles used throughout the application
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'ProscheNext', sans-serif;
  scroll-behavior: smooth;
}

#app {
  button {
    cursor: pointer;
    outline: none;
  }

  input {
    &:focus {
      outline: none;
    }
  }
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  input[type='checkbox'] {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    background: #f2f2f2;
    -webkit-appearance: none;
  }

  input:checked[type='checkbox'] {
    background-color: #000;
  }

  .check-icon {
    position: absolute;
    left: 5px;
    width: 14px;
    height: auto;
    pointer-events: none;
    path {
      fill: #fff;
    }
  }
}

.circle-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  input[type='checkbox'] {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    background: #f2f2f2;
    border-radius: 50%;
    appearance: none;
  }

  input:checked[type='checkbox'] {
    background-color: #f2f2f2;
  }

  input:checked[type='checkbox']::after {
    position: absolute;
    width: 20px;
    height: 20px;
    content: '';
    background-color: #000;
    border-radius: 50%;
  }

  input[type='radio'] {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    background: #f2f2f2;
    border-radius: 50%;
    -webkit-appearance: none;
  }

  input:checked[type='radio'] {
    background-color: #f2f2f2;
  }

  input:checked[type='radio']::after {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    content: '';
    background-color: #000;
    border-radius: 50%;
  }
}

.required-field {
  color: $primary;
}

// Mini component/feature styling
.toggle-switch {
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    position: relative;
    width: 53px;
    height: 34px;
    background: #e9e9ea;
    border: none;
    border-radius: 16px;
    outline: none;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: 0.5s ease all;
    -webkit-appearance: none;
  }

  input:checked[type='checkbox'] {
    background: #d5001c;
  }

  input[type='checkbox']::before {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 28px;
    height: 28px;
    content: '';
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: 0.5s ease all;
    transform: scale(1.1);
  }

  input:checked[type='checkbox']::before {
    left: 22px;
    background: #fff;
  }
}

// tag defaults
.tag {
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  background-color: #e3e4e5;
  border-radius: 2px;
}

.notification-alert {
  padding: 16px 20px;
  background-color: #e5eff8;
  border-left: 4px solid #0061bd;
}

// pagination
.pagination {
  gap: 24px;
  align-items: center;
  justify-content: center;
  margin: 24px 0 60px 0;

  .icon {
    height: 21px;
    cursor: pointer;
  }

  .icon:last-child {
    transform: rotate(-180deg);
  }

  .page {
    padding: 0 6px;
    font-size: 16px;
    cursor: pointer;
    border-bottom: 1px solid transparent;
  }

  .active-page {
    border-bottom-color: #d5001c;
  }
}

// Error Handling
.progress-update-enter-active,
.progress-update-leave-active {
  transition: 0.3s ease all;
}

.progress-update-enter,
.progress-update-leave-to {
  opacity: 0;
}

.status-msg {
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-size: 16px;
}

.success {
  color: $green;
}

.error {
  color: $primary;
}

.cancel-icon {
  width: auto;
  height: 24px;
  margin-right: 6px;
  path {
    fill: $primary;
  }
}

.check-icon {
  width: auto;
  height: 16px;
  margin-right: 10px;
}
