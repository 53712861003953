
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        




















































































































































.audit-trail-view {
  margin-top: 40px;

  .view-content {
    .audit-filter {
      display: flex;
      margin: 25px 0;

      .display-count {
        width: 109px;
        margin-bottom: 0;
        .multiselect__tags {
          padding: 12px 20px 12px 52px;
        }

        .label {
          position: absolute;
          top: 12px;
          left: 12px;
          z-index: 99;
          color: #000;
        }
      }

      .porsche-button {
        margin-left: auto;
      }
    }

    .audit-trail-wrap {
      position: relative;

      hr {
        margin: 16px 0;
      }

      .labels,
      .audit-data div {
        display: flex;
        gap: 32px;
        span {
          flex-basis: 8%;
          &:nth-child(4) {
            flex-basis: 25%;
          }
          &:nth-child(5) {
            flex-basis: 30%;
          }
          &:last-child {
            flex-basis: 20%;
          }
        }
      }

      .labels {
        span {
          font-weight: 500;
        }
      }

      .audit-trail-content {
        position: relative;

        .audit-data {
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
