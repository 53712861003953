
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        































.alert-enter-active {
  transition: 0.3s ease all;
}

.alert-enter {
  opacity: 0;
}

.status-msg {
  display: flex;
  align-items: center;
  margin-left: 0;
  font-size: 16px;

  .check-icon {
    width: auto;
    height: 16px;
    margin-right: 10px;
  }
}

.success {
  color: $green;
}

.inline {
  margin-left: 24px;
}

.block {
  margin-bottom: 16px;
}
