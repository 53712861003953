.navigation,
.mobile-navigation {
  nav {
    position: relative;

    .svg-icon {
      width: 17px;
    }

    .nav-top {
      .search-navigation {
        display: flex;
        flex: 1;
        align-items: center;

        .svg-icon {
          width: 24px !important;
          margin-right: 24px;
        }

        .svg-icon:last-child {
          margin-left: auto;
        }

        input {
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          border: none;

          &:focus {
            outline: none;
          }
        }
      }

      .content {
        display: flex;
        flex: 1;
        align-items: center;

        h2 {
          flex: 1;
          font-size: 24px;
          font-weight: 500;
        }

        img {
          z-index: 99;
          width: 121px;
          height: auto;
          background-color: #fff;
          outline: 30px solid #fff;
        }

        .user-settings {
          display: flex;
          flex: 1;
          justify-content: flex-end;

          .nav-link {
            padding-bottom: 0;

            &::after {
              bottom: -8px;
            }
          }

          .link {
            margin-right: 16px;
            @include bp-custom-min(1024) {
              margin-left: initial;
            }
          }

          .icon {
            display: flex;
            align-items: center;
            .svg-icon {
              margin-right: 0;
              @include bp-custom-min(1024) {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }

    .line {
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #c9cacb;
    }

    .nav-bottom {
      position: relative;
    }
  }
}
