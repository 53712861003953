// Place SCSS variables here
$primary: #d5001c;
$primary-hover: #980014;
$white-smoke: #f2f2f2;
$grey-extra-light: #c9cacb;
$grey-light: #96989a;
$grey: #626669;
$charcoal: #323639;
$green: #018a16;
$light-blue: #e5eff8;
$dark-blue: #0061bd;
