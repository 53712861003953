
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        























































































































































































































































































































































































































































































































































































































































































































































































































.wb-view {
  display: flex;
  flex: 1;
  column-gap: 16px;
  overflow: initial;

  &-section {
    flex: 1;
  }

  &-notes {
    padding: 15px 18px;
    background-color: $white-smoke;

    .notes-heading {
      display: flex;
      column-gap: 16px;
      margin-bottom: 12px;

      .toggle {
        display: flex;
        align-items: center;
        padding: 4px 2px;
        background-color: #000;

        .arrow-icon {
          width: auto;
          height: 24px;
          transition: 300ms ease all;
          transform: rotateZ(180deg);
        }
      }
    }

    .notes-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      .note {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        padding: 12px;
        border: 1px solid $grey-extra-light;

        &-heading {
          display: flex;
          column-gap: 16px;
          align-items: flex-start;

          div {
            display: flex;
            flex: 1;
            flex-direction: column;
          }

          .chevron-icon {
            width: auto;
            height: 22px;
            transition: 300ms ease-in-out all;
            transform: rotateZ(90deg);

            &-active {
              transform: rotateZ(-90deg);
            }

            path:nth-child(2) {
              fill: #000;
            }
          }
        }

        textarea {
          height: 200px;
          padding: 12px 8px;
          font-size: 16px;
          line-height: 1.2;
          resize: none;
          border: 1px solid $grey-extra-light;

          &:focus {
            border-color: $dark-blue;
            outline: none;
          }
        }

        &-timestamp {
          display: flex;
          column-gap: 4px;

          span {
            font-size: 12px;
            font-weight: 500;
          }
        }

        &-actions {
          display: flex;
          column-gap: 16px;

          > div {
            display: flex;
            column-gap: 6px;
            align-items: center;
          }

          .edit,
          .delete {
            cursor: pointer;
          }

          .edit-icon {
            width: 12px;
            height: auto;

            path {
              fill: $primary;
            }
          }

          .delete-icon {
            width: 12px;
            height: auto;
          }
        }

        &-active {
          background-color: $light-blue;
          border-color: $dark-blue;
        }
      }
    }

    &-active {
      width: 360px;

      .notes-heading {
        .toggle {
          .arrow-icon {
            transform: rotateZ(0deg);
          }
        }
      }
    }
  }

  &-notes-creator {
    pointer-events: initial;
    opacity: 1;

    .creator {
      display: flex;
      align-items: center;

      .add,
      .cancel-icon {
        cursor: pointer;
      }

      .add {
        display: flex;
        align-items: center;
        .add-icon {
          z-index: 102;
          width: auto;
          height: 18px;
          margin-right: 12px;
        }
      }

      .cancel-icon {
        margin-left: 30px;
        path {
          fill: #000;
        }
      }
    }
  }
}

.wb-view-mobile {
  .wb-view-section {
    padding-right: 70px;
  }

  .wb-view-notes {
    position: absolute;
    right: -10px;
    height: 100%;

    &-active {
      width: 270px;
    }

    .note {
      &-edit {
        flex-direction: column;
        row-gap: 8px;

        div {
          .porsche-button {
            flex: 1;
          }
        }
      }
    }
  }
}
