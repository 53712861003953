
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        



























.tool-tip {
  position: absolute;
  z-index: 99;
  display: flex;
  padding: 14px 16px;
  white-space: nowrap;
  pointer-events: none;
  background-color: #fff;
  box-shadow: 0 0 8px #0003;
  opacity: 0;
  transition: 0.3s ease all;

  &::after,
  &::before {
    position: absolute;
    pointer-events: none;
    content: '';
    background-color: #fff;
    transition: 0.3s ease all;
  }

  &::after {
    top: -4px;
    left: 12px;
    z-index: 100;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
  }

  span {
    margin: 0;
    color: #000;
  }
}

.tool-tip-right {
  &::after {
    right: 12px;
    left: initial;
  }
}
