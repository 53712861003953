
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        









































































































































































































































































































































































































































































































































.section-view {
  .save-content {
    margin-top: 24px;
  }

  .asset-builder {
    .view-title {
      margin-bottom: 24px;
    }
  }
}
