
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        
// shared styling through all related workbook builder components
.create-workbook {
  .input,
  .textarea {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    label {
      margin-bottom: 6px;
    }

    input,
    select,
    textarea {
      width: 100%;
      padding: 12px;
      font-size: 16px;
      background-color: #fff;
      border: 1px solid #626669;
      &:focus {
        outline: none;
      }
    }
  }

  hr {
    margin: 36px 0;
    background-color: #c9cacb;
  }

  .disclaimer {
    margin-top: 4px;
    font-size: 12px;
    color: #4a4a4a;
  }

  .progress {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 14px;
  }

  .view-title {
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
  }

  .textarea {
    .quill-editors {
      .ql-toolbar {
        background-color: #f2f2f2;
      }

      .ql-editor {
        height: 165px;
      }
    }
  }

  .checkbox {
    display: flex;
    flex-direction: row !important;
    align-items: center;
  }

  .mutltiple-inputs {
    display: flex;
    gap: 16px;

    div {
      flex: 1;
    }
  }

  .required-field {
    color: $primary;
  }

  // module / section shared styling
  .section-view,
  .module-view {
    .view-heading {
      display: flex;
      flex-direction: column;
      margin-bottom: 26px;

      .view-title {
        flex: 1;
      }

      .porsche-button {
        align-self: flex-end;
      }
    }
  }

  .module-content,
  .section-content {
    max-width: 832px;

    .hide-icon {
      width: 18px;
      height: 16px;
    }

    .delete-icon {
      width: 12px;
      height: 16px;
    }

    .header {
      display: flex;
      column-gap: 16px;
      align-items: center;

      .asset-type {
        display: flex;
        flex: 1;
        align-items: center;

        .icon,
        h3 {
          cursor: pointer;
        }

        .icon {
          width: 17px;
          height: 24px;
        }

        h3 {
          flex: 1;
          margin-left: 16px;
          font-size: 24px;
          text-transform: capitalize;
        }
      }

      .asset-toggle {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .status-msg {
          margin-right: 16px;
        }
      }
    }

    .asset-navigation,
    .notes-navigation {
      margin-top: 32px;
      ul {
        display: flex;
        list-style: none;
        .nav-link {
          margin-right: 20px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .create-new {
      margin-top: 32px;

      .notes-navigation {
        margin: 32px 0;
      }
    }

    .content-upload {
      display: flex;
      gap: 24px;
      margin-bottom: 30px;

      .or {
        flex-basis: 2%;
        margin-top: 40px;
      }

      .disabled {
        background-color: #f2f2f2;
      }
    }

    .input {
      flex: 1;

      .file-upload {
        display: flex;
        align-items: center;
        min-height: 49px;
        padding: 12px;
        font-size: 16px;
        border: 1px solid #626669;

        input[type='file'] {
          display: none;
        }

        .file-name {
          flex: 1;
          width: 100%;
          max-width: 150px;
          height: 23px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .icon-wrap {
          display: flex;
          flex: 1;
          column-gap: 8px;
          align-items: center;
          justify-content: flex-end;
          margin-bottom: 0;
          cursor: pointer;

          .remove-icon {
            width: auto;
            height: 22px;
            path {
              fill: $primary;
            }
          }

          .upload-icon {
            width: auto;
            height: 17px;
          }
        }

        .remove-upload {
          color: $primary;
        }
      }

      .porsche-button {
        align-self: flex-start;
        margin-top: 12px;
      }
    }

    .dropzone {
      width: 100%;
      height: 190px;
      margin-top: 16px;
      border: 1px dashed #4a4a4a;

      &::before {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        content: 'Drag and drop an asset type from above to build out this section';
      }
    }

    .asset-builders {
      .asset-tab {
        align-items: center;
        padding: 4px 10px;
        margin-right: 8px;
        cursor: pointer;
        border: 1px solid #c9cacb;
        border-radius: 6px;

        span {
          font-size: 16px;
        }
        .icon {
          width: 12px;
          height: 17px;
          margin-right: 8px;
        }
      }
    }
  }
}
