
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































.side-panel-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);

  .side-panel {
    position: fixed;
    right: 0;
    left: initial;
    width: 100%;
    max-width: 785px;
    height: 100%;
    min-height: 100vh;
    padding: 36px 36px 76px 36px;
    overflow: scroll;
    background-color: #fff;

    .header-error {
      font-size: 24px;
    }

    .close-report {
      display: flex;
      align-items: center;
      margin-bottom: 36px;
      cursor: pointer;

      .arrow-red {
        width: 6px;
        height: 13px;
        margin-right: 6px;
        transform: rotate(180deg);
      }
    }

    h2 {
      margin-bottom: 16px;
      font-size: 36px;
      font-weight: 500;
    }

    .panel-summary {
      margin-bottom: 24px;
    }

    .required {
      color: #d5001c;
    }

    .report-column {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    .checkbox {
      display: flex;
      align-items: center;
    }

    .half-size {
      max-width: 348.5px;
    }

    .input {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      label {
        display: block;
        margin-bottom: 6px;
      }
    }

    .sessions-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .label {
        display: block;
        margin-bottom: 8px;
      }

      .session {
        display: flex;
        row-gap: 20px;

        .checkbox {
          align-self: start;
          margin-bottom: 0;
        }

        &-content {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          .field {
            color: $grey-light;
            .label {
              display: initial;
              color: #000;
            }
          }

          .date {
            display: flex;
            column-gap: 20px;
          }
        }
      }
    }

    hr {
      margin: 32px 0;
    }

    .report-type {
      display: flex;
      flex-direction: column;

      h3 {
        margin-bottom: 16px;
        font-size: 24px;
        font-weight: 500;
      }

      .data-options {
        display: flex;
        margin-top: 6px;
      }

      .porsche-button {
        align-self: flex-start;
        margin-top: 16px;
      }
    }

    .survey-data {
      .report-column {
        max-width: 237.67px;
      }
    }
  }
}

.reports {
  margin-top: 40px;

  h1 {
    font-size: 36px;
  }

  .reports-filter {
    position: relative;
    display: flex;
    gap: 10px;
    margin: 25px 0;

    .filter {
      position: relative;
      z-index: 99;
      display: flex;
      align-items: center;
      padding: 10px 12px;
      cursor: pointer;
      background-color: #fff;
      border: 1px solid #626669;
      .filter-icon {
        height: 16px;
        margin-left: 18px;
      }
    }

    .button-actions {
      display: flex;
      gap: 10px;
      margin-left: auto;
    }

    .filter-menu-active {
      border-bottom-color: transparent;
    }

    .filter-menu-wrap {
      position: absolute;
      top: 48px;
      left: 119px;
      z-index: 98;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: 100%;
      max-width: 600px;
      padding: 24px 26px;
      background-color: #fff;
      border: 1px solid #626669;

      .generated-by,
      .date-picker {
        max-width: 265px;
      }

      .filter-menu {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .module-info {
          display: flex;
          gap: 16px;

          div {
            flex: 1;
          }
        }
      }

      .filter-actions {
        display: flex;
        gap: 16px;
      }
    }
  }

  .reports-table {
    margin-top: 40px;

    hr {
      margin: 16px 0;
    }

    .labels,
    .report {
      display: flex;
      gap: 16px;
      span {
        // &:nth-child(1) {
        //   display: flex;
        //   flex-basis: 5%;
        //   justify-content: center;
        // }
        &:nth-child(1) {
          flex-basis: 20%;
        }

        &:nth-child(2) {
          flex-basis: 14%;
        }

        &:nth-child(3) {
          flex-basis: 19%;
        }

        &:nth-child(4) {
          flex-basis: 10%;
        }

        &:nth-child(5) {
          flex-basis: 15%;
        }

        &:nth-child(6) {
          flex-basis: 10%;
        }

        &:nth-child(7) {
          flex-basis: 7%;
        }
      }
    }

    .labels {
      span {
        font-weight: 500;
      }
    }

    .report-wrap {
      position: relative;
      .report {
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .checkbox {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0;

          .check-icon {
            path {
              fill: #f2f2f2;
            }
          }

          input:checked {
            .check-icon {
              path {
                fill: #f2f2f2;
              }
            }
          }
        }

        .actions {
          display: flex;
          gap: 8px;
          align-items: center;

          .download-icon-wrap {
            position: relative;
            display: flex;
            align-items: center;

            .download-icon {
              width: 24px;
              height: 17px;
              cursor: pointer;
            }
          }
          .delete-icon {
            width: 12px;
            height: 26px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
