@font-face {
  font-family: ProscheNext;
  src: url('/src/assets/fonts/PorscheNext-Thin.woff') format('woff'),
    url('/src/assets/fonts/PorscheNext-Thin.woff2') format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: ProscheNext;
  src: url('./assets/fonts/PorscheNext-Regular.woff') format('woff'),
    url('./assets/fonts/PorscheNext-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: ProscheNext;
  src: url('./assets/fonts/PorscheNext-SemiBold.woff') format('woff'),
    url('./assets/fonts/PorscheNext-SemiBold.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: ProscheNext;
  src: url('./assets/fonts/PorscheNext-Bold.woff') format('woff'),
    url('./assets/fonts/PorscheNext-Bold.woff2') format('woff2');
  font-weight: 600;
}
