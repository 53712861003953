
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        




















.p-calendar {
  display: flex;
  flex-direction: column;

  .p-inputtext:enabled:hover,
  .p-inputtext:enabled:focus {
    border-color: #626669;
  }

  .cal-icon {
    position: absolute;
    left: 0;
    width: 20px;
    height: auto;
  }

  .p-datepicker {
    top: 52px !important;

    .p-datepicker-header .p-datepicker-next:focus,
    .p-datepicker-header .p-datepicker-prev:focus {
      box-shadow: none;
    }

    .p-timepicker span {
      font-size: 16px;
    }

    table {
      td {
        padding: 0;
        padding-bottom: 4px;
      }

      td.p-datepicker-today > span,
      td.p-datepicker-today > span.p-highlight {
        color: #fff;
        background-color: $primary;
      }

      td > span {
        width: 2rem;
        height: 2rem;
      }

      td > span.p-highlight {
        color: #fff;
        background-color: rgba(213, 0, 28);
      }

      td > span:focus {
        box-shadow: none;
      }

      tr {
        padding-bottom: 4px;
      }
    }

    .p-datepicker-buttonbar {
      padding: 8px 0;
      .p-button.p-button-text {
        color: $primary;
      }

      .p-button:focus {
        box-shadow: none;
      }

      .p-button.p-button-text:hover {
        color: #fff;
        background-color: $primary;
      }

      .p-button.p-button-text:enabled:active {
        color: #fff;
        background-color: none;
        border-color: none;
      }
    }
  }
}

.mobile {
  .p-datepicker {
    top: -420px !important;
  }
}
