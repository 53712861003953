
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        






































































































































































































































































































































































































































































































































































































.quiz-results-wrap {
  position: relative;

  .quiz-results {
    .result-header {
      .result-options {
        display: flex;
        align-items: center;

        .session-type::v-deep {
          width: 400px;
          margin-left: 16px;
          .label-wrap {
            position: absolute;
            top: 13px;
            left: 12px;
            z-index: 99;
            color: #000;
          }

          .multiselect__tags {
            padding: 12px 30px 12px 70px !important;

            .multiselect__single {
              padding: 0;
              margin-bottom: 0;
              overflow: scroll;
              -ms-overflow-style: none;
              scrollbar-width: none;
              white-space: nowrap;

              &::-webkit-scrollbar {
                display: none;
              }
            }
          }
        }
      }
    }
    .quiz-content-wrap {
      position: relative;
    }
  }
}
