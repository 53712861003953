
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        










































































































































































































































































































































































































































































































































































.side-panel-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);

  .side-panel {
    position: fixed;
    right: 0;
    left: initial;
    width: 100%;
    max-width: 450px;
    height: 100%;
    min-height: 100vh;
    padding: 36px 36px 76px 36px;
    overflow: scroll;
    background-color: #fff;

    .close-report {
      display: flex;
      align-items: center;
      margin-bottom: 36px;
      cursor: pointer;

      .arrow-red {
        width: 6px;
        height: 13px;
        margin-right: 6px;
        transform: rotate(180deg);
      }
    }

    .panel-title {
      margin-bottom: 14px;
    }

    .active-user {
      &-info {
        display: flex;
        column-gap: 24px;
        margin-bottom: 20px;
      }

      &-field {
        display: flex;
        flex-direction: column;
        row-gap: 6px;
      }

      &-content {
        font-weight: 500;
      }
    }

    .vue-multiselect {
      margin-bottom: 32px;
    }

    .porsche-button {
      align-self: start;
    }
  }
}

.search {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 62px;
  margin-top: 25px;

  .svg-icon {
    width: 24px;
  }
  input {
    flex-grow: 1;
    height: 40px;
    padding-right: 24px;
    padding-left: 4px;
    margin-left: 24px;
    font-size: 16px;
    border: 0;
    border-bottom: 1px solid #c9cacb;
  }
  .cancel-btn {
    position: absolute;
    right: 0;
    width: 20px;
    height: 100%;
  }
}

.admin-users {
  margin-top: 40px;

  .view-heading {
    row-gap: 40px;
  }

  .user-filter {
    position: relative;
    display: flex;
    margin: 25px 0;

    .filter-options {
      z-index: 99;
      display: flex;
      flex: 1;
      gap: 10px;

      .vue-multiselect {
        margin-bottom: 0;
      }

      .display-count,
      .sort-by {
        .label {
          position: absolute;
          top: 12px;
          left: 12px;
          z-index: 99;
          color: #000;
        }
      }

      .display-count {
        width: 109px;
        .multiselect__tags {
          padding: 12px 20px 12px 52px;
        }
      }

      .sort-by {
        width: 220px;
        .multiselect__tags {
          padding: 12px 20px 12px 60px;
        }
      }

      .filter {
        position: relative;
        z-index: 99;
        align-items: center;
        padding: 10px 12px;
        cursor: pointer;
        background-color: #fff;
        border: 1px solid #626669;
        .filter-icon {
          height: 16px;
          margin-left: 18px;
        }
      }

      .filter-menu-active {
        border-bottom-color: transparent;
      }
    }

    .filter-menu-wrap {
      position: absolute;
      top: 48px;
      left: 349px;
      z-index: 98;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: 100%;
      max-width: 550px;
      padding: 24px 26px;
      background-color: #fff;
      border: 1px solid #626669;

      .filter-menu {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .filter-row {
          display: flex;
          gap: 16px;

          .vue-multiselect {
            flex-basis: 48%;
          }
        }
      }

      .filter-actions {
        display: flex;
        gap: 16px;
      }
    }

    .button-actions {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-left: auto;

      .update {
        font-size: 14px;
      }
    }
  }

  .users-table {
    margin-top: 40px;

    hr {
      margin: 16px 0;
    }

    .labels,
    .user {
      display: flex;
      gap: 16px;
      span,
      div {
        flex: 1;

        &:nth-child(4) {
          flex-basis: 10%;
        }

        &:nth-child(5) {
          flex-basis: 15%;
        }

        &:nth-child(8) {
          flex-basis: 3%;
        }
      }
    }

    .labels {
      span {
        font-weight: 500;
      }
    }

    .user-wrap {
      position: relative;
    }

    .user {
      span,
      .pma-roles {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .pma-actions {
        display: flex;
        column-gap: 16px;
        .user-action {
          position: relative;
          flex: initial;

          .edit-icon {
            width: 12px;
            height: auto;
            cursor: pointer;

            path {
              fill: $primary;
            }
          }

          .report-icon {
            width: 20px;
            height: auto;
            cursor: pointer;
          }

          .tool-tip {
            top: 30px;
            right: -13px;
            padding: 8px 12px;
          }

          &:hover {
            .tool-tip {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
