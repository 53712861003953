
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        
.quiz-results-wrap,
.survey-results-wrap {
  .header-error {
    margin-top: 40px;
  }

  .result-header {
    display: flex;
    align-items: center;
    margin-top: 24px;

    h1 {
      margin-right: 16px;
      font-size: 24px;
      font-weight: 500;
    }

    .vue-multiselect {
      min-width: 250px;
      margin-bottom: 0;
    }

    .porsche-button {
      margin-left: auto;
    }
  }

  .survey-results,
  .quiz-results {
    margin-bottom: 120px;
    .results {
      display: flex;
      flex-direction: column;
      gap: 26px;
      padding: 24px;
      margin-top: 16px;
      border: 1px solid #c9cacb;

      .row {
        display: flex;
        flex-wrap: wrap;
        row-gap: 48px;
        .stat {
          display: flex;
          flex-basis: 20%;
          flex-direction: column;
          .label {
            font-size: 16px;
            font-weight: 500;
          }
          .data {
            font-size: 24px;
          }
        }
      }
    }

    .user-results {
      margin-top: 20px;
      h1 {
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: 500;
      }

      .search {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 62px;
        margin: 25px 0;

        .svg-icon {
          width: 24px;
        }
        input {
          flex-grow: 1;
          height: 40px;
          padding-right: 24px;
          padding-left: 4px;
          margin-left: 24px;
          font-size: 16px;
          border: 0;
          border-bottom: 1px solid #c9cacb;
        }
        .cancel-btn {
          position: absolute;
          right: 0;
          width: 20px;
          height: 100%;
        }
      }

      .filter-options {
        position: relative;
        display: flex;
        gap: 10px;

        .display-count {
          width: 109px;
          margin-bottom: 0;
          .multiselect__tags {
            padding: 12px 20px 12px 52px;
          }
          .label {
            position: absolute;
            top: 12px;
            left: 12px;
            z-index: 99;
            color: #000;
          }
        }

        .filter-wrap {
          position: relative;
          z-index: 98;
          display: flex;
          flex: 1;

          .filter {
            position: relative;
            align-items: center;
            padding: 10px 12px;
            cursor: pointer;
            background-color: #fff;
            border: 1px solid #626669;

            .filter-icon {
              height: 16px;
              margin-left: 18px;
            }
          }

          .filter-menu-active {
            &::after {
              position: absolute;
              bottom: -1px;
              left: 0;
              z-index: 99;
              width: 100%;
              height: 2px;
              content: '';
              background-color: white;
            }
          }

          .filter-menu-wrap {
            position: absolute;
            top: 50px;
            left: 0;
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            width: 100%;
            max-width: 600px;
            padding: 24px 26px;
            background-color: #fff;
            border: 1px solid #626669;

            .filter-menu {
              display: flex;
              flex-direction: column;
              gap: 16px;

              div {
                flex: 1;
              }
            }

            .filter-actions {
              display: flex;
              gap: 16px;
            }
          }
        }
      }

      .user-results-data-wrap {
        position: relative;
        min-height: 200px;
        .users-table {
          margin-top: 40px;

          hr {
            margin: 16px 0;
          }

          .labels,
          .user {
            display: flex;
            gap: 16px;
            span,
            .actions {
              flex: 1;
              &:nth-child(4) {
                flex: 3;
              }
            }
          }

          .labels {
            span {
              font-weight: 500;
            }
          }

          .users-wrap {
            position: relative;
          }

          .user {
            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .actions {
              display: flex;
              gap: 8px;
              align-items: center;
              cursor: pointer;

              .action {
                position: relative;

                .download-icon {
                  width: 24px;
                  height: 17px;
                }

                .reset-icon {
                  width: 22px;
                  height: 25px;
                }

                .tool-tip {
                  top: 30px;
                  right: -10px;
                  padding: 8px 12px;
                }

                &:hover {
                  .tool-tip {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
