
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

















































































































































































































































































































































































































































































































































































.training {
  .arrow-down {
    width: 16px;
    height: auto;
    margin-left: 18px;
    transform: rotate(-90deg);
  }

  .view-content {
    position: relative;
    min-height: 70vh;

    .training-filter {
      position: relative;
      display: flex;
      margin: 25px 0;

      // Shared Styling
      .filter-options,
      .filter-options-mobile {
        z-index: 99;
        display: flex;
        flex: 1;
        gap: 10px;

        .filter {
          position: relative;
          z-index: 99;
          display: flex;
          align-items: center;
          padding: 10px 12px;
          cursor: pointer;
          background-color: #fff;
          border: 1px solid #626669;
          .filter-icon {
            height: 16px;
            margin-left: 18px;
          }
        }

        .filter-menu-active {
          border-bottom-color: transparent;
        }
      }

      // Filter Options for mobile

      .filter-options-mobile {
        flex-direction: column;
        .filter {
          .filter-icon {
            margin-left: auto;
          }
        }
        div:nth-child(2) {
          column-gap: 24px;
          .display {
            flex: 1;
          }

          .sort-by {
            flex: 2;
          }
        }
      }

      .filtered-options {
        display: flex;
        gap: 8px;
        align-items: center;
        .tag {
          display: flex;
          align-items: center;
          padding: 4px 8px;
          font-size: 12px;
          background-color: #e3e4e5;
          border-radius: 2px;
          .icon {
            height: 16px;
            margin-right: 7px;
            cursor: pointer;
          }
        }
      }

      .filter-menu {
        position: absolute;
        top: 48px;
        left: 349px;
        z-index: 98;
        display: flex;
        flex-direction: column;
        max-width: max-content;
        padding: 24px 26px;
        background-color: #fff;
        border: 1px solid #626669;

        .porsche-button {
          align-self: flex-start;
        }
      }

      .filter-menu-mobile {
        left: 0;
        z-index: 100;
        max-width: 100%;
      }

      .favorites-navigation {
        display: flex;
        column-gap: 20px;
        align-items: center;
        justify-self: flex-end;

        a {
          display: flex;
          column-gap: 4px;
          align-items: center;
          color: initial;
          text-decoration: none;
        }

        .router-link-active {
          color: $primary;
          .fav-icon {
            g {
              fill: $primary;
            }
          }
        }

        .fav-icon {
          width: 16px;
          height: auto;
        }
      }
    }

    .training-filter-mobile {
      flex-direction: column;
      row-gap: 20px;

      .favorites-navigation {
        order: 1;
      }

      .filter-options-mobile {
        order: 2;
      }
    }

    .training-content-wrap {
      position: relative;
      .training-content {
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        min-height: 70vh;

        .workbooks {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
