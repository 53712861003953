
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        



































































.input {
  margin-bottom: 16px;
  input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #626669;
  }

  .invalid-field {
    border-color: $primary;
  }
}
