
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        






















































.pagination {
  gap: 24px;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  margin: auto 0 60px 0;

  .paginate {
    display: flex;
    gap: 20px;
    list-style: none;

    li {
      a {
        padding: 0 6px;
        font-size: 16px;
        cursor: pointer;
        border-bottom: 1px solid transparent;
      }

      &:first-child,
      &:last-child {
        display: none;
      }
    }

    .active {
      a {
        border-bottom-color: #d5001c;
      }
    }
  }

  .icon {
    height: 21px;
    cursor: pointer;
  }

  .icon:last-child {
    transform: rotate(-180deg);
  }
}
