
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        








































































.training-navigation {
  margin-top: 32px;
  ul {
    display: flex;
    border-bottom: 2px solid #c9cacb;

    .nav-link {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }

    .inactive {
      color: $grey-light;
      pointer-events: none;
    }
  }
}
