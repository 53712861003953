
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        





























































































































































































































































































































































































































































































































































.vehicle-comparison-tool {
  .breadcrumbs {
    margin-top: 24px;
  }

  .header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 22px;

    span {
      margin-left: auto;
      font-size: 14px;
    }
  }

  .comparison {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    .section-label {
      padding: 13px 0;
      margin: 38px 0;
      background-color: #f2f2f2;

      .label {
        font-weight: 600;
      }
    }

    .grid {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 60px;

      &:first-child {
        .label {
          align-items: flex-end;
          font-weight: 600;
        }
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .label {
        justify-content: flex-end;
        text-align: right;
      }

      .input {
        display: flex;
        flex-direction: column;
        .image-wrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 105px;
          margin-bottom: 20px;
        }
        img {
          height: 100%;
          object-fit: cover;
        }

        .car-icon {
          max-height: 79px;
        }
      }
    }

    hr {
      margin: 32px 0;
    }
  }
}
