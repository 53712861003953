
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        























































































































































































































































































































































































































































































































































































































































































































































































































































































































































.asset {
  position: relative;
  padding: 40px 34px;
  border: 1px solid #7c7f81;

  .add-icon {
    width: 17px;
    height: 17px;
    margin-right: 8px;
  }

  .chevron {
    width: 12px;
    height: 16px;
    transition: 0.5s ease all;
    transform: rotate(90deg);
    path {
      fill: #000;
    }
  }

  .arrow-active {
    transform: rotate(270deg);
  }

  .arrow-icon {
    width: 12px;
    height: auto;
  }

  .reorder {
    position: absolute;
    top: -12px;
    left: 20px;
    z-index: 1000;
    align-items: center;
    padding: 0 16px;
    background-color: #fff;
    .dots-icon {
      width: 4px;
      height: auto;
      margin-right: 6px;
    }
  }

  .header {
    display: flex;
    align-items: center;

    .left {
      display: flex;
      align-items: center;

      h3 {
        margin-left: 16px;
        font-size: 24px;
      }
    }

    .icon {
      width: 17px;
      height: 24px;
    }
  }

  .hotspot-creator {
    display: flex;
    column-gap: 16px;

    .content-upload {
      flex-basis: 50%;
      flex-direction: column;
      justify-content: flex-start;

      .add-pin {
        display: flex;
        align-items: center;
      }

      .input {
        flex: initial;
        margin-bottom: 0;
      }

      .hotspot-img {
        position: relative;
        img {
          display: block;
          width: 100%;
          height: auto;
        }

        .hotspot-markers {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;

          span {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            background-color: #fff;
            border-radius: 50%;
          }
        }
      }
    }

    .hotspot-pins {
      position: relative;
      flex-basis: 50%;
      padding-left: 40px;

      .pin {
        .display-pin {
          position: absolute;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          background-color: $white-smoke;
          border-radius: 50%;
        }

        .hotspot-types {
          display: flex;
          column-gap: 16px;
          margin-bottom: 24px;
          list-style: none;
        }

        .input {
          margin-bottom: 24px;
          .porsche-button {
            align-self: flex-end;
          }
        }

        .pin-notes {
          display: flex;
          flex-direction: column;

          textarea {
            height: 100px;
            padding: 8px 12px;
            resize: none;
            border: 2px solid $grey-light;
          }
        }

        .pin-actions {
          display: flex;
          column-gap: 16px;
          align-items: center;
          margin-top: 16px;

          .action {
            display: flex;
            flex: 1;
            column-gap: 8px;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
  }

  .asset-actions {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    margin-top: 24px;

    .toggle-switch {
      flex: 1;
      gap: 10px;
    }

    div {
      align-items: center;
      cursor: pointer;
      span {
        margin-left: 6px;
      }
    }
  }
}

.content-lib-search {
  display: flex;
  flex-direction: column;
  .search-heading {
    display: flex;
    column-gap: 8px;
    margin-bottom: 6px;
  }

  .search-query {
    margin-bottom: 24px;
    input {
      width: 100%;
      padding-left: 40px;
    }

    .icon-wrap {
      position: absolute;
      left: 13px;
      display: flex;
      align-items: center;
      height: 100%;

      .search-icon {
        width: 18px;
        height: auto;
      }
    }
  }

  .search-results {
    .search-asset {
      display: flex;
      flex-direction: column;
      .asset-info {
        display: flex;
        .asset-content {
          display: flex;
          flex: 1;
          column-gap: 16px;
          img,
          video {
            width: 75px;
            height: auto;
          }

          .asset-details {
            h3 {
              margin-bottom: 4px;
              font-size: 16px;
            }

            .view-media {
              display: flex;
              align-items: center;
              margin-bottom: 8px;
              font-size: 14px;
              cursor: pointer;

              .arrow-icon {
                width: auto;
                height: 16px;
              }
            }

            .tags {
              display: flex;
              column-gap: 4px;
            }
          }
        }
        .asset-select {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          .arrow-icon {
            width: auto;
            height: 20px;
          }
        }
      }
    }

    .arrow-icon {
      path:nth-child(2) {
        fill: $primary;
      }
    }
  }
}
