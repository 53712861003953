
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        






































































.search-wrap {
  position: relative;

  h1 {
    margin-top: 60px;
    margin-bottom: 32px;
  }

  .search {
    .search-results {
      .result {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .search-content {
          h2,
          p {
            margin-bottom: 12px;
          }

          h2 {
            font-size: 16px;
          }

          .tags {
            display: flex;
            column-gap: 8px;
          }
        }

        hr {
          margin: 32px 0;
        }
      }
    }
  }
}
